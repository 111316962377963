import SvgIcon, { SvgIconProps } from "./SvgIcon";

const LoadingSpinnerIcon: React.FC<SvgIconProps> = ({
  color = "#1F2A37",
  ...props
}) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 24 24" {...props}>
      <path
        fill={color}
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
      >
        <animateTransform
          attributeName="transform"
          dur="1.125s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        />
      </path>
    </SvgIcon>
  );
};

export default LoadingSpinnerIcon;
