"use client";

import React, { useEffect, useState } from "react";
import { getItem } from "@utils/localStorage";
import {
  readFromIndexedDB,
  removeKeyFromIndexedDB,
} from "@utils/indexed-db/db";
import { useCalendarState } from "@react-stately/calendar";
import Upload from "@icons/Upload";
import { Button, Chip, Tooltip } from "@nextui-org/react";
import { UploadData } from "@utils/indexed-db/enums";
import { Spinner } from "@nextui-org/spinner";
import LoadingSpinnerIcon from "@icons/LoadingSpinnerIcon";
import { toast, ToastT } from "sonner";
import Typography from "@components/Typography";
import ProgressBar from "@components/__Retool/UploadFiles/ProgressBar/ProgressBar";
import { Progress } from "@nextui-org/progress";
import Check from "@icons/Check";

interface ToastNotificationInterface {
  title: string;
  totalSize: number;
  uploadedSize: number;
  status: "idle" | "uploading" | "completed" | "failed";
}

const UploadProgress = () => {
  const [toastVisibility, setToastVisibility] = useState<boolean>(true);
  const [toastList, setToastList] = useState<string[]>([]);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [totalUpload, setTotalUpload] = useState<number>(0);
  const [progressList, setProgressList] = useState<UploadData[] | undefined>(
    undefined,
  );

  // Setup timeout to check indexedDB for upload status
  // Add useEffect + timeout to check indexedDB for file progress and render toast notification
  useEffect(() => {
    const interval = setInterval(() => {
      const uploadId = getItem("content_request_upload");
      if (uploadId) {
        readFromIndexedDB(uploadId)
          .then((data) => {
            if (data) {
              setProgressList(data.files);
              if (data.files.every((file) => file.status === "completed")) {
                removeKeyFromIndexedDB(uploadId).then(() => {
                  setToastList([]);
                });
              }
            }
          })
          .catch((err) => {
            console.error("Error reading upload progress:", err);
          });
      }
    }, 1000); // Poll every 1 second

    return () => clearInterval(interval); // Clean up when component unmounts
  }, []);

  useEffect(() => {
    if (!progressList) return;

    // Compute values
    setTotalFiles(progressList.length);
    let completedFiles = 0;
    const activeToastIds = [];
    for (const file of progressList) {
      if (file.status !== "completed") {
        activeToastIds.push(file.uploadName);
      }
      if (file.status === "completed") {
        completedFiles += 1;
      }
      // Call toast
      if (toastVisibility && file.status !== "completed") {
        toast.info(
          <ToastNotification
            status={"uploading"}
            title={file.uploadName}
            totalSize={file.totalSize}
            uploadedSize={file.totalSize - file.totalRemaining}
          />,
          {
            id: file.uploadName,
          },
        );
      }
      if (toastVisibility && file.status === "completed") {
        toast.success(
          <ToastNotification
            status={"completed"}
            title={file.uploadName}
            totalSize={file.totalSize}
            uploadedSize={file.totalSize - file.totalRemaining}
          />,
          {
            id: file.uploadName,
          },
        );
      }
    }
    setTotalUpload(completedFiles);
    setToastList(activeToastIds);

    // Run same for to check if files are completed
  }, [progressList]);

  const ToastNotification = ({
    title,
    totalSize,
    uploadedSize,
    status,
  }: ToastNotificationInterface) => {
    if (status === "completed") {
      return (
        <div className="flex w-full flex-col items-start justify-between">
          <div className="flex w-full flex-row items-center justify-between">
            <Typography type={"body"} size="md" weight={"semibold"}>
              {title}
            </Typography>
            <Chip size="sm" variant={"light"} color="danger">
              <Typography
                role={"button"}
                onClick={DismissToastList}
                type={"body"}
                size={"xs"}
              >
                Hide all
              </Typography>
            </Chip>
          </div>
          <div>
            <Typography type={"body"} size={"md"}>
              Upload completed
              <Check color={"rgb(4, 135, 108)"} />
            </Typography>
          </div>
        </div>
      );
    }
    return (
      <div className="flex w-full flex-col items-start justify-between">
        <div className="flex w-full flex-row items-center justify-between">
          <Typography type={"body"} size="md" weight={"semibold"}>
            {title}
          </Typography>
          <Chip size="sm" variant={"light"} color="danger">
            <Typography
              role={"button"}
              onClick={DismissToastList}
              type={"body"}
              size={"xs"}
            >
              Hide all
            </Typography>
          </Chip>
        </div>
        <Progress
          label={
            "Upload progress - " +
            String(((uploadedSize / totalSize) * 100).toFixed(2)) +
            "%"
          }
          isStriped
          size={"sm"}
          value={totalUpload}
          maxValue={totalSize}
          classNames={{
            indicator: "bg-[#087867]",
          }}
        />
      </div>
    );
  };

  const DismissToastList = () => {
    setToastVisibility(false);
    if (!progressList) return;
    for (const file of progressList) {
      toast.dismiss(file.uploadName);
    }
  };

  const handleDetailsToast = () => {
    setToastVisibility(true);
  };

  if (toastList?.length > 0) {
    return (
      <>
        <div className="flex w-full flex-col items-start justify-start text-[rgb(var(--media-gray-500))]">
          <Tooltip
            content={
              <div
                className="px-1 py-2"
                role="button"
                onClick={handleDetailsToast}
              >
                <div className="text-small font-bold">
                  {"Total files uploaded: " + totalUpload + "/" + totalFiles}
                </div>
                <div className="text-tiny">Press to expand details</div>
              </div>
            }
          >
            <Button
              startContent={<LoadingSpinnerIcon color={"#6C737F"} />}
              onClick={handleDetailsToast}
            >
              Upload in progress
            </Button>
          </Tooltip>
        </div>
      </>
    );
  }
};

export default UploadProgress;
