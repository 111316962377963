"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import React from "react";
import OlynLogo from "/public/static/OLYN.svg";
import {
  cn,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Switch,
  User,
} from "@nextui-org/react";
import Stack from "@icons/Stack";
import Share from "@icons/Share";
import PresentationChart from "@icons/PresentationChart";
import CoinsHand from "@icons/CoinsHand";
import Settings from "@icons/Settings";
import { MyButton } from "./Button";
import { userLogout } from "@utils/userLogout";
import Logout from "@icons/Logout";
import DefaultAvatar from "@icons/DefaultAvatar";
import { SvgIconProps } from "@icons/SvgIcon";
import Login from "@icons/Login";
import { getItem } from "@utils/localStorage";
import { useAuth } from "@context/Auth";
import Roles, { RolesToLabel } from "@enums/Roles";
import Typography from "./Typography";
import Transactions from "@icons/Transactions";
import UploadProgress from "@components/General/UplaodProgress";

const links: {
  roles?: Roles[];
  position: "start" | "middle" | "end";
  title: string;
  url?: string;
  click?: VoidFunction;
  mobile?: boolean;
  condition?: () => boolean;
  icon?: React.FunctionComponent<SvgIconProps>;
}[] = [
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Home",
    url: "/",
    icon: Stack,
  },
  {
    roles: [Roles["creator.admin"]],
    position: "middle",
    title: "Partners",
    url: "/partners",
    icon: Share,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Analytics",
    url: "/analytics",
    icon: PresentationChart,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Viewer Experience",
    url: "/user-experience",
    icon: PresentationChart,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Transactions",
    url: "/transactions",
    icon: Transactions,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Earnings",
    url: "/earnings",
    icon: CoinsHand,
  },
  /*  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Settings",
    url: "/settings",
    icon: Settings,
  }, */
  {
    position: "end",
    condition: () => !Boolean(getItem("access_token")),
    mobile: false,
    title: "Log in",
    url: "/login",
    icon: Login,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "end",
    condition: () => Boolean(getItem("access_token")),
    title: "Log Out",
    click: userLogout,
    icon: Logout,
  },
];

const SideNavigation = () => {
  const { user, role, roles, setActiveRole, logout } = useAuth();
  const pathname = usePathname();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      shouldHideOnScroll
      height="var(--sidebar-mobile-height)"
      onMenuOpenChange={setIsMenuOpen}
      classNames={{
        base: "sm:absolute sm:float-start h-header-mobile sm:h-dynamic-screen w-full sm:w-sideBar-mobile sm:hover:w-sideBar overflow-hidden transition-all delay-100 md:w-sideBar sm:px-2 md:px-[var(--media-spacing-16)] sm:pt-[var(--media-spacing-28)] sm:pb-[var(--media-spacing-24)] bg-[rgb(var(--media-gray-100))] flex-col items-start border-0 border-r-1 border-[rgb(var(--media-gray-200))]",
        wrapper:
          "h-header sm:flex-col sm:items-start sm:p-0 h-full sm:w-[calc(var(--sidebar-width)_-_var(--media-spacing-16)_*_2)] gap-0",
        content: "sm:flex-col sm:items-stretch sm:!gap-2 sm:w-full",
        brand: "shrink-0 grow-0",
      }}
    >
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>
      <NavbarContent className="sm:hidden" justify="center">
        <NavbarBrand className="basis-[100px]">
          <Link href="/">
            <Image height={12.3} src={OlynLogo} alt="Olyn logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent
        className="hidden h-auto shrink-0 grow-0 sm:flex"
        justify="center"
      >
        <NavbarBrand>
          <Link
            className="mb-4 px-[var(--media-spacing-12)] sm:mb-6 lg:mb-7 xl:mb-8"
            href="/"
          >
            <Image height={12.3} src={OlynLogo} alt="Olyn logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden gap-4 sm:flex" justify="start">
        {links.map(
          ({
            condition = () => true,
            position,
            roles,
            title,
            url,
            click,
            icon: Icon,
          }) => {
            if (
              position !== "middle" ||
              !condition?.() ||
              !roles?.includes(role!)
            )
              return;

            const props: Parameters<typeof MyButton>["0"] = {
              color: pathname === url ? "activeNavLink" : "navLink",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
            }

            if (click) {
              props["onPress"] = click;
            }

            if (title === "Log Out") {
              props["onPress"] = logout;
            }

            return (
              <NavbarItem key={url ?? title} isActive={pathname === url}>
                <MyButton
                  fullWidth
                  radius="sm"
                  className="justify-start"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarItem>
            );
          },
        )}
      </NavbarContent>
      <NavbarContent justify="end">
        {links.map(
          ({
            condition = () => true,
            position,
            title,
            url,
            click,
            roles,
            icon: Icon,
          }) => {
            if (
              position !== "end" ||
              !condition?.() ||
              (roles && !roles.includes(role!))
            )
              return;

            const props: Parameters<typeof MyButton>["0"] = {
              color: pathname === url ? "activeNavLink" : "navLink",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
            }

            if (click) {
              props["onPress"] = click;
            }

            if (title === "Log Out") {
              props["onPress"] = logout;
            }

            return (
              <NavbarItem
                key={url ?? title}
                className="hidden sm:flex"
                isActive={pathname === url}
              >
                <MyButton
                  fullWidth
                  radius="sm"
                  className="justify-start"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarItem>
            );
          },
        )}
        {/*{roles && roles.length > 1 && (*/}
        {/*  <NavbarItem className="flex items-center gap-2">*/}
        {/*    <Typography className="uppercase" type="body" size="xs">*/}
        {/*      {RolesToLabel["md.admin"]}*/}
        {/*    </Typography>*/}
        {/*    <Switch*/}
        {/*      color="default"*/}
        {/*      size="sm"*/}
        {/*      defaultSelected={role === Roles["creator.admin"]}*/}
        {/*      onValueChange={(value: boolean) => {*/}
        {/*        setActiveRole(*/}
        {/*          value ? Roles["creator.admin"] : Roles["md.admin"],*/}
        {/*        );*/}
        {/*      }}*/}
        {/*    ></Switch>*/}
        {/*    <Typography className="uppercase" type="body" size="xs">*/}
        {/*      {RolesToLabel["creator.admin"]}*/}
        {/*    </Typography>*/}
        {/*  </NavbarItem>*/}
        {/*)}*/}
        <UploadProgress />
        <NavbarItem>
          <User
            name="Account"
            description={user?.email ?? ""}
            classNames={{
              base: "sm:px-3 sm:mt-4 w-full justify-start gap-2",
              wrapper: "max-w-[calc(100%_-_28px_-_0.5rem)]",
              name: "max-w-full text-[rgb(var(--media-gray-500))] typography-body-xs-medium",
              description:
                "max-w-full text-[rgb(var(--media-gray-700))] typography-body-xs-medium truncate",
            }}
            avatarProps={{
              src: "",
              classNames: {
                base: "shrink-0 bg-transparent sm:w-5 sm:h-5 md:w-7 md:h-7",
              },
              fallback: (
                <DefaultAvatar
                  size={28}
                  className={"text-[rgb(var(--media-gray-400))]"}
                  color="currentColor"
                />
              ),
            }}
          />
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        {links.map(
          ({
            condition = () => true,
            mobile = true,
            title,
            roles,
            url,
            click,
            icon: Icon,
          }) => {
            if (!mobile || !condition?.() || !roles?.includes(role!)) return;

            const props: Parameters<typeof MyButton>["0"] = {
              variant: pathname === url ? "bordered" : "ghost",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
            }

            if (click) {
              props["onPress"] = click;
            }

            if (title === "Log Out") {
              props["onPress"] = logout;
            }

            return (
              <NavbarMenuItem key={url ?? title}>
                <MyButton
                  fullWidth
                  color="navLink"
                  className="justify-start"
                  size="lg"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarMenuItem>
            );
          },
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default SideNavigation;
