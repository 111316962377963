import SvgIcon, { SvgIconProps } from "./SvgIcon";
import React from "react";

const Check: React.FC<SvgIconProps> = ({ color = "#000000", ...props }) => {
  return (
    <SvgIcon width={24} height={24} color="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M16.8 8.3999L9.64043 15.5999L7.19995 13.1456"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Check;
