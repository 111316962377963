export const DB_NAME = "uploadDB";
export const DB_VERSION = 1;
export const STORE_NAME = "uploads";

export interface UploadStatus {
  id: string; // Unique identifier for the upload batch (e.g., user ID or session ID)
  files: UploadData[]; // Array of file upload statuses
}

export interface UploadData {
  uploadName: string;
  totalRemaining: number;
  totalSize: number;
  status: "idle" | "uploading" | "completed" | "failed";
}
