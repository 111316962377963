// Open or create the IndexedDB
import {
  DB_NAME,
  DB_VERSION,
  STORE_NAME,
  UploadStatus,
} from "@utils/indexed-db/enums";

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function (event) {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = function (event) {
      resolve((event.target as IDBOpenDBRequest).result);
    };

    request.onerror = function (event) {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
}

// Write to IndexedDB
export function writeToIndexedDB(data: UploadStatus): Promise<void> {
  return openDatabase().then((db) => {
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const store = transaction.objectStore(STORE_NAME);
    store.put(data);

    return new Promise((resolve, reject) => {
      transaction.oncomplete = function () {
        resolve();
      };

      transaction.onerror = function () {
        reject(transaction.error);
      };
    });
  });
}

// Read from IndexedDB
export function readFromIndexedDB(
  key: string,
): Promise<UploadStatus | undefined> {
  return openDatabase().then((db) => {
    const transaction = db.transaction([STORE_NAME]);
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = function () {
        resolve(request.result as UploadStatus | undefined);
      };

      request.onerror = function () {
        reject(request.error);
      };
    });
  });
}

//@ts-ignore
export async function removeKeyFromIndexedDB(key: string): Promise<unknown> {
  const db = await openDatabase(); // Ensure this opens the IndexedDB

  return new Promise((resolve, reject) => {
    // Open a read-write transaction (not 'readonly')
    const transaction = db.transaction([STORE_NAME], "readwrite");
    const objectStore = transaction.objectStore(STORE_NAME);

    const request = objectStore.delete(key); // Delete the record with the specific key

    request.onsuccess = () => {
      console.log(
        `Key '${key}' has been successfully deleted from the database.`,
      );
      resolve(true);
    };

    request.onerror = () => {
      console.error(`Failed to delete key '${key}' from the database.`);
      reject(request.error);
    };
  });
}
